import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VARIABLES } from "../../Constants";
import axios from "axios";

function MachineList() {
  const [machines, setMachines] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const getData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.machineUrl}project-nodes`,
      headers: {
        "project-key": VARIABLES.projectKey,
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        setMachines(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  });
  return (
    <div className="table-responsive" style={{ borderRadius: "10px" }}>
      <table class="table" style={{ whiteSpace: "nowrap" }}>
        <thead>
          <tr className="position-sticky top-0 table-dark">
            <th scope="col">#Sr. No</th>
            <th scope="col">Machine</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {machines?.length !== 0 ? (
            machines.map((mach, i) => {
              return (
                <tr
                  className={`align-middle table-${
                    mach.status === "active" ? "success" : ""
                  }`}
                >
                  <th scope="row" className="ps-4">
                    {i + 1}
                  </th>
                  <td>{mach.name}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm"
                      onClick={() =>
                        navigate(`/machinedashboard/${mach.nodeID}`)
                      }
                    >
                      View
                    </button>
                  </td>
                </tr>
              );
            })
          ) : !isLoading && machines === null ? (
            <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
              NO DATA
            </h3>
          ) : (
            <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
              Loading..
            </h3>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MachineList;
