import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function DateDropdowns({
  filter,
  setFilter,
  setWeekNo,
  setMonth,
  setYear,
  setStartDate,
  setEndDate,
  setDailyDate,
  dailyDate,
  month,
  currentYearDefault,
}) {
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const year = new Date("2000-01-01").getFullYear();
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    new Array(currentYear - year + 1),
    (val, index) => index + year
  );
  function getISOWeeks(year) {
    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31);
    const weeks = [];
    let currentWeek = 1;
    let currentDate = startDate;
    while (currentDate <= endDate) {
      weeks.push({
        week: currentWeek,
        startDate: new Date(currentDate),
        endDate: new Date(currentDate.setDate(currentDate.getDate() + 6)),
      });
      currentWeek++;
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setDate(
        currentDate.getDate() + ((1 - currentDate.getDay() + 7) % 7)
      );
    }
    return weeks;
  }
  const weekNumbers = getISOWeeks(2024);
  return (
    <div className="d-flex align-items-center">
      {filter !== "daily" && (
        <>
          {filter === "yearly" ||
          filter === "monthly" ||
          filter === "weekly" ? (
            <select
              class="form-select form-select-sm ms-auto"
              aria-label="Default select example"
              style={{ width: "15%", height: "24px", fontSize: ".8em" }}
              onChange={(e) => setYear(e.target.value)}
              value={currentYearDefault}
            >
              <option>Select Year</option>
              {years.map((year, i) => {
                return <option value={`${i}`}>{year}</option>;
              })}
            </select>
          ) : (
            ""
          )}

          {filter === "monthly" && (
            <select
              class="form-select form-select-sm ms-3"
              aria-label="Default select example"
              style={{ width: "25%", height: "24px", fontSize: ".8em" }}
              onChange={(e) => setMonth(e.target.value)}
              value={month}
            >
              <option>Select Month</option>
              {months.map((month, i) => {
                return <option value={`${month.value}`}>{month.label}</option>;
              })}
            </select>
          )}
          {filter === "weekly" && (
            <select
              class="form-select form-select-sm ms-3"
              style={{ width: "25%", height: "24px", fontSize: ".8em" }}
              onChange={(e) => setWeekNo(e.target.value)}
            >
              <option selected>Select Week</option>
              {weekNumbers.map((week, i) => {
                return <option value={`${week.week}`}>{week.week}</option>;
              })}
            </select>
          )}
        </>
      )}
      {filter === "custom" && (
        <>
          <div
            class="ms-auto d-flex align-items-center justify-content-center mx-1"
            style={{ width: "35%", height: "24px" }}
          >
            <label
              for="startdate"
              class="form-label me-2 pt-2"
              style={{ height: "24px", fontSize: ".8em", textWrap: "nowrap" }}
            >
              Start Date
            </label>
            <input
              class="form-control"
              type="date"
              id="startdate"
              style={{ height: "24px", fontSize: ".8em", width: "60%" }}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div
            class="d-flex align-items-center justify-content-center mx-1"
            style={{ width: "35%", height: "24px" }}
          >
            <label
              for="enddate"
              class="form-label me-2 pt-2"
              style={{ height: "24px", fontSize: ".8em", textWrap: "nowrap" }}
            >
              End Date
            </label>
            <input
              class="form-control"
              type="date"
              id="enddate"
              style={{ height: "24px", fontSize: ".8em", width: "60%" }}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </>
      )}
      {filter === "daily" && (
        <div
          class="ms-auto d-flex align-items-center justify-content-center mx-1"
          style={{ width: "40%", height: "24px" }}
        >
          <label
            for="startdate"
            class="form-label me-2 pt-2"
            style={{ height: "24px", fontSize: ".8em", textWrap: "nowrap" }}
          >
            Date
          </label>
          <input
            class="form-control"
            type="date"
            id="date"
            value={moment(dailyDate).format("YYYY-MM-DD")}
            style={{ height: "24px", fontSize: ".8em", width: "60%" }}
            onChange={(e) => setDailyDate(e.target.value)}
          />
        </div>
      )}
      <div class={`dropdown me-3}`}>
        <i
          class={`bi bi-funnel-fill ms-3 me-3`}
          role="button"
          data-bs-toggle="dropdown"
        ></i>
        <ul class="dropdown-menu ms-auto">
          <li>
            <Link class="dropdown-item" onClick={() => setFilter("daily")}>
              Daily
            </Link>
          </li>
          {/* <li>
            <Link class="dropdown-item" onClick={() => setFilter("weekly")}>
              Week
            </Link>
          </li> */}
          <li>
            <Link class="dropdown-item" onClick={() => setFilter("monthly")}>
              Month
            </Link>
          </li>
          <li>
            <Link class="dropdown-item" onClick={() => setFilter("yearly")}>
              Year
            </Link>
          </li>
          {/* <li>
            <Link class="dropdown-item" onClick={() => setFilter("custom")}>
              Custom
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default DateDropdowns;
