import Base from "./components/base/Base";
import "./App.css";
import { useState } from "react";
import Login from "./components/pages/Login";

function App() {
  const [isLogedIn, setIsLogedIn] = useState(true);
  return (
    <div className="App">
      {isLogedIn ? <Base /> : <Login setIsLogedIn={setIsLogedIn} />}
    </div>
  );
}

export default App;
