import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { VARIABLES } from "../Constants";

export const MachineContext = createContext(null);

function MatchineContextProvider({ children }) {
  const [nodeList, setNodeList] = useState(null);
  const getData = ({} = {}) => {
    let url = `${VARIABLES.machineUrl}project-nodes`;
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "project-key": VARIABLES.projectKey,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setNodeList(response.data.data.map((mach) => mach.nodeID));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const value = { nodeList };
  return (
    <MachineContext.Provider value={value}>{children}</MachineContext.Provider>
  );
}

export default MatchineContextProvider;
