import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { VARIABLES } from "../../Constants.js";
import axios from "axios";
import { MachineContext } from "../../context/MachineContextProvider";

function MachineStatus() {
  const [year, setYear] = useState("24");
  const [month, setMonth] = useState(2);
  const [weekNo, setWeekNo] = useState(null);
  const [filter, setFilter] = useState("daily");
  const [graphData, setGraphData] = useState([]);
  const [machineData, setMachineData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState();
  const [label, setLabel] = useState("");
  const [noData, setNoData] = useState(false);
  const [dailyDate, setDailyDate] = useState(new Date("2024-02-21"));
  const { nodeList } = useContext(MachineContext);
  const [isLoading, setIsLoading] = useState(true);
  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }
  const getData = ({ date = "2023-01-01" } = {}) => {
    let url = `${VARIABLES.url}multiple-machine-api?duration=custom&custom&date=${date}`;
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "project-key": VARIABLES.projectKey,
        "node-id": nodeList,
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (response.data.count === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        setIsLoading(false);
        console.log(response.data);
        setMachineData(response.data);
      })
      .catch((error) => {
        setNoData(true);
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    console.log(filter);

    if (filter === "daily") {
      getData({
        filter: filter,
        date: moment(new Date(dailyDate)).format("YYYY-MM-DD"),
        duration: filter,
      });
    }
    if (filter === "yearly") {
      if (year !== null) {
        getData({ filter: filter, year: parseInt(`20${year}`) });
      }
    }
    if (filter === "monthly") {
      if (year !== null && month !== null) {
        console.log(year);
        console.log(month);
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: "monthly",
          month: month,
        });
      }
    }
    if (filter === "weekly") {
      if (year !== null && weekNo !== null) {
        console.log(year);
        console.log(weekNo);
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: "weekly",
          month: weekNo,
        });
      }
    }
    if (filter === "custom") {
      if (startDate !== null && endDate !== null) {
        getData({
          filter: filter,
          duration: "custom",
        });
      }
    }
  }, [filter, year, month, weekNo, startDate, endDate, dailyDate]);
  useEffect(() => {
    console.log(machineData);
    if (machineData !== null) {
      const graphData = machineData?.data.map((item) => ({
        name: Object.keys(item)[0],
        status:
          item[Object.keys(item)]?.["Machine running"] === 1 &&
          item[Object.keys(item)]?.["Machine idle"] === 0
            ? "Machine is Running"
            : "Machine is Idle",
      }));

      setGraphData(graphData);
    }
  }, [machineData, filter]);
  useEffect(() => {
    getData({
      filter: filter,
      date: moment(new Date(dailyDate)).format("YYYY-MM-DD"),
      duration: filter,
    });
  }, [nodeList]);
  console.log(graphData);
  return (
    <div
      className="table-responsive "
      style={{ maxHeight: "85%", borderRadius: "5px" }}
    >
      <table class="table " style={{ whiteSpace: "nowrap" }}>
        <thead>
          <tr className="position-sticky top-0 table-dark border">
            <th scope="col">#Sr. No</th>
            <th scope="col">Machine</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading && graphData?.length !== 0 ? (
            graphData.map((e, i) => {
              return (
                <tr
                  className={`${
                    e.status !== "Machine is Running"
                      ? "table-warning"
                      : "table-success"
                  } border`}
                >
                  <th scope="row">{i + 1}</th>
                  <td>{e.name}</td>
                  <td>{e.status}</td>
                </tr>
              );
            })
          ) : !isLoading && graphData === null ? (
            <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
              NO DATA
            </h3>
          ) : (
            <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
              Loading..
            </h3>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MachineStatus;
