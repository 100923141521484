import React from "react";
import { Link } from "react-router-dom";

function Navbar({ showDrawer, setToggled }) {
  return (
    <nav
      class="navbar navbar-expand-lg bg-body-tertiary"
      style={{ width: "100%", height: "60px" }}
    >
      <div class="container-fluid">
        {showDrawer && (
          <i
            class="bi bi-list "
            style={{ fontSize: "2em", cursor: "pointer" }}
            onClick={() => setToggled((prev) => !prev)}
          ></i>
        )}
        <div class="dropdown ms-auto">
          <a className="me-3" href="https://www.elorca.com/" target="_blank">
            <img
              src={require("../../../res/elorca-logo.png")}
              height={48}
              width={48}
            />
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
