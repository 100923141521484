import React, { useState } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";

function Sidebar({ setShowDrawer, toggled, setToggled }) {
  const [collapsed, setCollapsed] = useState(false);
  const toggleSidebar = () => {
    setCollapsed((prev) => !prev);
  };
  return (
    <div>
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        style={{ height: "100vh", backgroundColor: "white" }}
        onBreakPoint={setShowDrawer}
        onBackdropClick={() => setToggled(false)}
        breakPoint="md"
      >
        <Menu
          menuItemStyles={{
            button: {
              // the active class will be added automatically by react router
              // so we can use it to style the active menu item
              [`&.active`]: {
                backgroundColor: "#1c8adb",
                color: "#fff",
              },
            },
          }}
        >
          <MenuItem
            icon={
              <img
                src={require("../../../res/ashtatech.png")}
                height={48}
                width={48}
              />
            }
            onClick={toggleSidebar}
            style={{ marginTop: "1em" }}
          >
            <h4 className="mt-1 ms-3">Ashta Tech</h4>
          </MenuItem>
          <MenuItem
            icon={
              <i
                class="bi bi-binoculars-fill"
                style={{ fontSize: "1.5em" }}
              ></i>
            }
            component={<NavLink to="/" />}
          >
            Complete View
          </MenuItem>
          <MenuItem
            icon={<i class="bi bi-gear-fill" style={{ fontSize: "1.5em" }}></i>}
            component={<NavLink to="/machineview" />}
          >
            Machines View
          </MenuItem>
          <MenuItem
            icon={
              <i class="bi bi-speedometer" style={{ fontSize: "1.5em" }}></i>
            }
            component={<NavLink to="/dashboard" />}
          >
            Dashboard
          </MenuItem>
        </Menu>
        <Menu style={{ position: "absolute", bottom: 10 }}>
          <MenuItem
            icon={
              <img
                height="50px"
                width="50px"
                src={require("../../../res/elorca-logo.png")}
              />
            }
            onClick={toggleSidebar}
            style={{ marginTop: "1em" }}
          >
            <p className="mt-3 ms-3">Powered by Elorca</p>
          </MenuItem>
        </Menu>
      </ProSidebar>
    </div>
  );
}

export default Sidebar;
