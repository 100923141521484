import React from "react";
import MachineHours from "../charts/MachineHours";
import ProductionCount from "../charts/ProductionCount";
import ProductionLine from "../charts/ProductionLine";
import MachineStages from "../tables/MachineStages";
import { useParams } from "react-router-dom";
import StatusTable from "../tables/StatusTable";

function MachineDashboard() {
  const { id } = useParams();
  console.log(id);
  return (
    <div className="container-fluid Box">
      <div className="row mt-3 mb-2 mx-3 d-flex justify-content-around">
        <div
          className="col-12 col-lg-5 mb-3 CardShadow"
          style={{
            minHeight: "380px",
            maxHeight: "380px",
            padding: "10px",
            margin: "10px 5px",
            borderRadius: "10px",
          }}
        >
          <MachineHours />
        </div>
        <div
          className="col-12 col-lg-6 mb-3 d-flex flex-column"
          style={{
            minHeight: "380px",
            maxHeight: "380px",
            padding: "10px",
            margin: "10px 5px",
            borderRadius: "10px",
          }}
        >
          <div
            className="mb-3 CardShadow"
            style={{
              minHeight: "130px",
              maxHeight: "130px",
              padding: "10px",
              margin: "10px 5px",
              borderRadius: "10px",
            }}
          >
            <ProductionCount />
          </div>
          <div
            className="mb-3 CardShadow"
            style={{
              minHeight: "200px",
              maxHeight: "200px",
              padding: "10px",
              margin: "10px 5px",
              borderRadius: "10px",
            }}
          >
            <StatusTable />
          </div>
        </div>
      </div>
      <div className="row mb-3 d-flex justify-content-around">
        <div
          className="col-12 col-lg-6 CardShadow"
          style={{
            minHeight: "350px",
            maxHeight: "350px",
            padding: "10px",
            margin: "10px 5px",
            borderRadius: "10px",
          }}
        >
          <ProductionLine />
        </div>
        <div
          className="col-12 col-lg-5 CardShadow"
          style={{
            minHeight: "350px",
            maxHeight: "350px",
            padding: "10px",
            margin: "10px 5px",
            borderRadius: "10px",
          }}
        >
          <MachineStages />
        </div>
      </div>
      {/* <div className="row mb-3 d-flex justify-content-around">
        <div
          className="col-11 CardShadow"
          style={{
            minHeight: "350px",
            maxHeight: "350px",
            padding: "10px",
            margin: "10px 5px",
            borderRadius: "10px",
          }}
        >
          <DemoDataTable />
        </div>
      </div> */}
    </div>
  );
}

export default MachineDashboard;
