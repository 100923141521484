import React from "react";
import TotalProductionBar from "../charts/TotalProductionBar";
import MachineStatus from "../tables/MachineStatus";
import MachineWiseProductionBar from "../charts/MachineWiseProductionBar";

function CompleteView() {
  return (
    <div className="container-fluid Box">
      <div className="row mt-3 mb-2 mx-3 d-flex justify-content-around">
        <div
          className="col-12 col-lg-5 CardShadow"
          style={{
            minHeight: "360px",
            maxHeight: "360px",
            padding: "10px",
            margin: "10px 5px",
            borderRadius: "10px",
          }}
        >
          <MachineWiseProductionBar />
        </div>
        <div
          className="col-12 col-lg-6 mb-2 CardShadow"
          style={{
            minHeight: "300px",
            maxHeight: "300px",
            padding: "10px",
            margin: "10px 5px",
            borderRadius: "10px",
          }}
        >
          <div className="d-flex">
            <h6 className="ms-4 mb-3">Machine Status</h6>
            {/* <div class="dropdown ms-auto">
              <i
                class="bi bi-funnel-fill me-3"
                role="button"
                data-bs-toggle="dropdown"
              ></i>
              <ul class="dropdown-menu ms-auto">
                <li>
                  <Link class="dropdown-item ">Year</Link>
                </li>
                <li>
                  <Link class="dropdown-item ">Month</Link>
                </li>
                <li>
                  <Link class="dropdown-item ">Week</Link>
                </li>
              </ul>
            </div> */}
          </div>
          <MachineStatus />
        </div>
      </div>
      <div className="row mb-2 mx-3 d-flex justify-content-around">
        <div
          className="col-12 CardShadow"
          style={{
            minHeight: "300px",
            maxHeight: "300px",
            padding: "10px",
            margin: "10px 5px",
            borderRadius: "10px",
          }}
        >
          <TotalProductionBar />
        </div>
      </div>
    </div>
  );
}

export default CompleteView;
