import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import CompleteView from "../pages/CompleteView";
import MachineView from "../pages/MachineView";
import MachineDashboard from "../pages/MachineDashboard";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./header/Navbar";

function Base() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [toggled, setToggled] = useState(false);
  return (
    <div className="d-flex">
      <Sidebar
        setShowDrawer={setShowDrawer}
        toggled={toggled}
        setToggled={setToggled}
      />
      <div style={{ Height: "100vh", width: "100%", overflow: "auto" }}>
        <Navbar showDrawer={showDrawer} setToggled={setToggled} />
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/" element={<CompleteView />} />
          <Route path="/machineview" element={<MachineView />} />
          <Route path="/machinedashboard/:id" element={<MachineDashboard />} />
        </Routes>
      </div>
    </div>
  );
}

export default Base;
