import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { VARIABLES } from "../../Constants";
import { useParams } from "react-router-dom";

function MachineStages() {
  const [machineData, setMachineData] = useState(null);
  const [noData, setNoData] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2024-02-21"));
  const [graphData, setGraphData] = useState(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const getData = ({
    date = moment.utc(currentDate).format("YYYY-MM-DD"),
  } = {}) => {
    let url = `${VARIABLES.url}latest-data-api?date=${date}`;
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "project-key": VARIABLES.projectKey,
        "node-id": id,
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.data.length === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        setIsLoading(false);
        setMachineData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setNoData(true);
        console.log(error);
      });
  };
  useEffect(() => {
    if (machineData !== null) {
      console.log(machineData);
      setGraphData([
        {
          name: "Air Pressure",
          value: machineData.data[0]?.["Air Pressure"],

          status:
            machineData.data[0]?.["Air Pressure"] === 0 ? "Error" : "No Error",
        },
        {
          name: "DC Power Failure",
          value: machineData.data[0]?.["DC Power Failure"],
          status:
            machineData.data[0]?.["DC Power Failure"] === 0
              ? "No Failure"
              : "Failure",
        },
        {
          name: "Machine idle",
          value: machineData.data[0]?.["Machine idle"],

          status:
            machineData.data[0]?.["Machine idle"] === 0 ? "Not Idle" : "Idle",
        },
        {
          name: "Machine running",
          value: machineData.data[0]?.["Machine running"],

          status:
            machineData.data[0]?.["Machine running"] === 0
              ? "Not Running"
              : "Running",
        },
        {
          name: "Machine Starts",
          value: machineData.data[0]?.["Machine starts"],
          status:
            machineData.data[0]?.["Machine starts"] === 0
              ? "Machine Stopped"
              : "Machine Started",
        },
        {
          name: "Roller 1 error",
          value: machineData.data[0]?.["Roller 1 error"],
          status:
            machineData.data[0]?.["Roller 1 error"] === 0
              ? "No Error"
              : "Error",
        },
        {
          name: "Roller 2 error",
          value: machineData.data[0]?.["Roller 2 error"],
          status:
            machineData.data[0]?.["Roller 2 error"] === 0
              ? "No Error"
              : "Error",
        },
        {
          name: "Conveyor 1 error",
          value: machineData.data[0]?.["Conveyor 1 error"],
          status:
            machineData.data[0]?.["Conveyor 1 error"] === 0
              ? "No Error"
              : "Error",
        },
        {
          name: "Conveyor 2 error",
          value: machineData.data[0]?.["Conveyor 2 error"],
          status:
            machineData.data[0]?.["Conveyor 2 error"] === 0
              ? "No Error"
              : "Error",
        },
        {
          name: "Z1-axis error",
          value: machineData.data[0]?.["Z1-axis error"],
          status:
            machineData.data[0]?.["Z1-axis error"] === 0 ? "No Error" : "Error",
        },
        {
          name: "Z2-axis error",
          value: machineData.data[0]?.["Z2-axis error"],
          status:
            machineData.data[0]?.["Z2-axis error"] === 0 ? "No Error" : "Error",
        },
      ]);
    }
  }, [machineData]);
  useEffect(() => {
    getData();
  }, [currentDate]);
  return (
    <>
      <h6 className="ms-4">Machine Stages</h6>
      <div
        class="d-flex align-items-center justify-content-center ms-auto"
        style={{ height: "24px" }}
      >
        <span className="me-auto ms-4" style={{ fontSize: ".9em" }}>
          Last Updated At:{" "}
          {moment.utc(machineData?.data[0]?.timestamp)?.format("h:mm:ss a")}
        </span>
        <label
          for="enddate"
          class="form-label me-2 pt-2 "
          style={{ height: "24px", fontSize: ".8em", textWrap: "nowrap" }}
        >
          Date
        </label>
        <input
          class="form-control"
          type="date"
          id="enddate"
          value={moment.utc(currentDate).format("YYYY-MM-DD")}
          style={{ height: "24px", fontSize: ".8em", width: "30%" }}
          onChange={(e) => setCurrentDate(e.target.value)}
        />
      </div>
      {!noData && graphData !== null && (
        <div
          className="table-responsive mt-3"
          style={{ maxHeight: "80%", borderRadius: "5px" }}
        >
          <table class="table" style={{ whiteSpace: "nowrap" }}>
            <thead>
              <tr className="position-sticky top-0 table-dark">
                <th scope="col">#Sr. No</th>
                <th scope="col">Stage</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {graphData !== null ? (
                graphData.map((e, i) => {
                  return (
                    <tr>
                      <th scope="row">{i + 1}</th>
                      <td>{e.name}</td>
                      <td>
                        <td>
                          {e.value !== null ? (
                            <span
                              class={`badge text-bg-${
                                e.status === "Error" || e.status === "Failure"
                                  ? "danger"
                                  : e.status === "No Error" ||
                                    e.status === "No Failure"
                                  ? "info"
                                  : "success"
                              }`}
                            >
                              {e.status}
                            </span>
                          ) : (
                            <span class={`badge text-bg-danger`}>NO DATA</span>
                          )}
                        </td>
                      </td>
                    </tr>
                  );
                })
              ) : !isLoading && graphData === null ? (
                <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
                  NO DATA
                </h3>
              ) : (
                <h3 className="text-center pt-3" style={{ fontSize: "2em" }}>
                  Loading..
                </h3>
              )}
            </tbody>
          </table>
        </div>
      )}
      {noData && (
        <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
          NO DATA
        </h3>
      )}
    </>
  );
}

export default MachineStages;
