import React from "react";
import MachineList from "../tables/MachineList";

function MachineView() {
  return (
    <div className="container">
      <h3 className="mb-3 mt-3">Machines List</h3>
      <MachineList />
    </div>
  );
}

export default MachineView;
