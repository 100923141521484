import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import DateDropdowns from "../dropdowns/DateDropdowns";
import axios from "axios";
import { VARIABLES } from "../../Constants.js";
import moment from "moment";
import { useParams } from "react-router-dom";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

function MachineHours() {
  const [year, setYear] = useState("24");
  const [month, setMonth] = useState(2);
  const [weekNo, setWeekNo] = useState(null);
  const [filter, setFilter] = useState("daily");
  const [machineData, setMachineData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [dailyDate, setDailyDate] = useState(new Date("2024-02-21"));
  const [label, setLabel] = useState("");
  const [noData, setNoData] = useState(false);
  const [tempData, setTempData] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const getData = ({
    filter = "yearly",
    start_date = "",
    end_date = "",
    year = 2023,
    week = 42,
    month = 2,
    duration = "yearly",
    date = "2023-01-01",
  } = {}) => {
    let url = `${VARIABLES.url}machine-status-api?duration=${duration}`;
    if (filter === "daily") {
      url += `&date=${date}`;
    }
    if (filter === "yearly") {
      url += `&year=${year}`;
    } else if (filter === "monthly") {
      url += `&year=${year}&month=${month}`;
    } else if (filter === "weekly") {
      url += `&year=${year}&month=${week}`;
    } else if (filter === "custom") {
      url += `&start_date=${start_date}&end_date=${end_date}`;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "project-key": VARIABLES.projectKey,
        "node-id": id,
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (response.data.data.length === 0) {
          setNoData(true);
          setTempData([]);
        } else {
          setNoData(false);
          setMachineData(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    console.log(filter);
    if (filter === "daily") {
      getData({
        filter: filter,
        date: moment(new Date(dailyDate)).format("YYYY-MM-DD"),
        duration: filter,
      });
      setLabel("Time");
    }
    if (filter === "yearly") {
      if (year !== null) {
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: filter,
        });
        setLabel("Months");
      }
    }
    if (filter === "monthly") {
      if (year !== null && month !== null) {
        console.log(year);
        console.log(month);
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: filter,
          month: month,
        });
        setLabel("Days");
      }
    }
    if (filter === "weekly") {
      if (year !== null && weekNo !== null) {
        console.log(year);
        console.log(weekNo);
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: filter,
          month: weekNo,
        });
      }
    }
    if (filter === "custom") {
      if (startDate !== null && endDate !== null) {
        getData({
          filter: filter,
          duration: filter,
          start_date: startDate,
          end_date: endDate,
        });
      }
    }
  }, [filter, year, month, weekNo, startDate, endDate, dailyDate]);
  console.log(VARIABLES[id]);
  useEffect(() => {
    console.log(noData);
  }, [noData]);
  useEffect(() => {
    if (machineData !== null) {
      console.log(machineData);
      setTempData([
        {
          name: "Working Hours",
          value:
            machineData?.data[0][Object.keys(machineData?.data[0])[0]][
              "machine_running_time"
            ],
        },
        {
          name: "Idle Hours",
          value:
            machineData?.data[0][Object.keys(machineData?.data[0])[0]][
              "machine_idle_time"
            ],
        },
      ]);
    }
  }, [machineData, filter]);
  console.log(tempData);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="GlassCard p-3">
          <p className="label">{`Hours : ${payload[0].value}`}</p>
          <p className="intro">{label}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <h6 className="ms-4">Machine Hours</h6>
      <DateDropdowns
        filter={filter}
        dailyDate={dailyDate}
        month={month}
        currentYearDefault={year}
        setFilter={setFilter}
        setWeekNo={setWeekNo}
        setMonth={setMonth}
        setYear={setYear}
        setDailyDate={setDailyDate}
      />
      <ResponsiveContainer width="100%" height="80%">
        {tempData.length !== 0 ? (
          <PieChart>
            <Pie
              data={tempData}
              cx="50%"
              cy="50%"
              innerRadius={50}
              outerRadius={90}
              fill="#8884d8"
              paddingAngle={2}
              dataKey="value"
              label
            >
              {tempData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend />
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        ) : noData ? (
          <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
            NO DATA
          </h3>
        ) : (
          <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
            Loading..
          </h3>
        )}
      </ResponsiveContainer>
    </>
  );
}

export default MachineHours;
