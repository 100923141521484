import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  Legend,
} from "recharts";
import DateDropdowns from "../dropdowns/DateDropdowns";
import moment from "moment";
import { VARIABLES } from "../../Constants.js";
import { MachineContext } from "../../context/MachineContextProvider";

function TotalProductionBar() {
  const [year, setYear] = useState("24");
  const [month, setMonth] = useState(2);
  const [weekNo, setWeekNo] = useState(null);
  const [filter, setFilter] = useState("daily");
  const [graphData, setGraphData] = useState([]);
  const [machineData, setMachineData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState();
  const [label, setLabel] = useState("");
  const [noData, setNoData] = useState(false);
  const [dailyDate, setDailyDate] = useState(new Date("2024-02-21"));
  const { nodeList } = useContext(MachineContext);
  const [isLoading, setIsLoading] = useState(true);
  const getData = ({
    filter = "yearly",
    start_date = "",
    end_date = "",
    year = 2023,
    week = 42,
    month = 2,
    duration = "yearly",
    date = "2023-01-01",
  } = {}) => {
    let url = `${VARIABLES.url}multiple-machine-api?duration=${duration}`;
    if (filter === "daily") {
      url += `&date=${date}`;
    }
    if (filter === "yearly") {
      url += `&year=${year}`;
    } else if (filter === "monthly") {
      url += `&year=${year}&month=${month}`;
    } else if (filter === "weekly") {
      url += `&year=${year}&month=${week}`;
    } else if (filter === "custom") {
      url += `&start_date=${start_date}&end_date=${end_date}`;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "project-key": VARIABLES.projectKey,
        "node-id": nodeList,
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (response.data.data.length === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        setIsLoading(false);
        setMachineData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setGraphData([]);
        console.log(error);
      });
  };
  useEffect(() => {
    console.log(filter);
    if (filter === "daily") {
      getData({
        filter: filter,
        date: moment.utc(new Date(dailyDate)).format("YYYY-MM-DD"),
        duration: filter,
      });
    }
    if (filter === "yearly") {
      if (year !== null) {
        getData({ filter: filter, year: parseInt(`20${year}`) });
      }
    }
    if (filter === "monthly") {
      if (year !== null && month !== null) {
        console.log(year);
        console.log(month);
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: "monthly",
          month: month,
        });
      }
    }
    if (filter === "weekly") {
      if (year !== null && weekNo !== null) {
        console.log(year);
        console.log(weekNo);
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: "weekly",
          month: weekNo,
        });
      }
    }
    if (filter === "custom") {
      if (startDate !== null && endDate !== null) {
        getData({
          filter: filter,
          duration: "custom",
        });
      }
    }
  }, [filter, year, month, weekNo, startDate, endDate, dailyDate]);
  useEffect(() => {
    if (machineData !== null) {
      setIsLoading(true);
      setGraphData(
        machineData?.data[0]?.[
          Object.keys(machineData?.data[0])
        ]?.timestamp?.map((time, i) => {
          const productionCount =
            machineData?.data.reduce((sum, item) => {
              const key = Object.keys(item)[0];
              return sum + item[key]["Count 1"][i];
            }, 0) || 0;

          const formattedTime =
            filter !== "daily"
              ? filter === "yearly"
                ? moment.utc(time).format("MMMM YY")
                : moment.utc(time).format("MMM DD YY")
              : moment.utc(time).format("LT");

          return {
            name: formattedTime,
            production: productionCount,
          };
        })
      );
    }
  }, [machineData, filter]);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="GlassCard p-3">
          <p className="label">{`Production : ${payload[0].value}`}</p>
          <p className="intro">{label}</p>
        </div>
      );
    }
    return null;
  };
  useEffect(() => {
    console.log(noData);
  }, [noData]);
  useEffect(() => {
    getData({
      filter: filter,
      date: moment.utc(new Date(dailyDate)).format("YYYY-MM-DD"),
      duration: filter,
    });
  }, [nodeList]);
  console.log(graphData);
  return (
    <>
      <h6 className="ms-4">Total Production</h6>
      <DateDropdowns
        filter={filter}
        dailyDate={dailyDate}
        month={month}
        currentYearDefault={year}
        setFilter={setFilter}
        setWeekNo={setWeekNo}
        setMonth={setMonth}
        setYear={setYear}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setDailyDate={setDailyDate}
      />
      <ResponsiveContainer width="100%" height="80%">
        {graphData?.length !== 0 ? (
          <BarChart
            data={graphData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" style={{ fontSize: ".8em" }}>
              <Label
                value={label}
                offset={-10}
                style={{
                  textAnchor: "middle",
                  fontSize: "80%",
                  fill: "rgba(0, 0, 0)",
                  fontWeight: "bolder",
                }}
                position="insideBottom"
              />
            </XAxis>
            <YAxis style={{ fontSize: ".8em" }}>
              <Label
                angle={270}
                position="insideLeft"
                offset={10}
                value="Count 1"
                style={{
                  textAnchor: "middle",
                  fontSize: "80%",
                  fill: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "bolder",
                }}
              />
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" />
            <Bar
              dataKey="production"
              fill="#8884d8"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
          </BarChart>
        ) : noData ? (
          <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
            NO DATA
          </h3>
        ) : (
          <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
            Loading..
          </h3>
        )}
      </ResponsiveContainer>
    </>
  );
}

export default TotalProductionBar;
