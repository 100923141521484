import React, { useState, useEffect } from "react";
import axios from "axios";
import DateDropdowns from "../dropdowns/DateDropdowns";
import moment from "moment";
import { VARIABLES } from "../../Constants.js";
import { useParams } from "react-router-dom";

function StatusTable() {
  const [machineData, setMachineData] = useState(null);
  const [noData, setNoData] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2024-02-21"));
  const [graphData, setGraphData] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const getData = ({
    date = moment(currentDate).format("YYYY-MM-DD"),
  } = {}) => {
    let url = `${VARIABLES.url}count-api?duration=daily&date=${date}`;
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "project-key": VARIABLES.projectKey,
        "node-id": id,
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.data.length === 0) {
          setNoData(true);
          setGraphData([]);
        } else {
          setMachineData(response.data);
          setNoData(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    if (machineData !== null) {
      console.log(machineData);
      let lastrun = 0;
      let lastidle = 0;

      const timestamps = machineData?.data[0]["timestamp"];
      const machineIdle = machineData?.data[0]["Machine idle"];
      const machineRunning = machineData?.data[0]["Machine running"];

      for (let i = timestamps.length - 1; i >= 0; i--) {
        if (machineIdle[i] === 0 && machineRunning[i] === 1 && lastrun === 0) {
          lastrun = timestamps[i];
        } else if (
          ((machineIdle[i] === 1 && machineRunning[i] === 0) ||
            (machineIdle[i] === 1 && machineRunning[i] === 1)) &&
          lastidle === 0
        ) {
          lastidle = timestamps[i];
        }

        if (lastrun !== 0 && lastidle !== 0) {
          break; // Exit loop if both values are found
        }
      }

      setGraphData([
        {
          name: "Last Running",
          value: moment.utc(lastrun).format("hh:mm:ss a"),
          // value: lastrun,
        },
        {
          name: "Last Idle",
          value: moment.utc(lastidle).format("hh:mm:ss a"),
          // value: lastidle,
        },
      ]);
    }
  }, [machineData]);
  useEffect(() => {
    getData();
  }, [currentDate]);
  console.log(graphData);
  return (
    <>
      <h6 className="ms-4">Machine Status</h6>
      <div
        class="d-flex align-items-center justify-content-center ms-auto"
        style={{ height: "24px" }}
      >
        <label
          for="enddate"
          class="form-label me-2 pt-2 ms-auto"
          style={{ height: "24px", fontSize: ".8em", textWrap: "nowrap" }}
        >
          Date
        </label>
        <input
          class="form-control"
          type="date"
          id="enddate"
          value={moment(currentDate).format("YYYY-MM-DD")}
          style={{ height: "24px", fontSize: ".8em", width: "30%" }}
          onChange={(e) => setCurrentDate(e.target.value)}
        />
      </div>
      <div
        className="table-responsive mt-2"
        style={{ maxHeight: "70%", borderRadius: "5px" }}
      >
        <table class="table" style={{ whiteSpace: "nowrap" }}>
          <thead>
            <tr className="position-sticky top-0 table-dark">
              <th scope="col">#Sr. No</th>
              <th scope="col">Status</th>
              <th scope="col">Time</th>
            </tr>
          </thead>
          <tbody>
            {graphData.length !== 0 ? (
              graphData.map((e, i) => {
                return (
                  <tr>
                    <th scope="row">{i + 1}</th>
                    <td>{e.name}</td>
                    <td>{e?.value || "NO DATA"}</td>
                  </tr>
                );
              })
            ) : noData ? (
              <h3 className="text-center pt-3" style={{ fontSize: "2em" }}>
                NO DATA
              </h3>
            ) : (
              <h3 className="text-center pt-3" style={{ fontSize: "2em" }}>
                Loading..
              </h3>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default StatusTable;
