import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { VARIABLES } from "../../Constants";
import { useParams } from "react-router-dom";

function ProductionCount() {
  const [machineData, setMachineData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2024-02-21"));
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const getData = ({
    date = moment(currentDate).format("YYYY-MM-DD"),
  } = {}) => {
    let url = `${VARIABLES.url}latest-data-api?date=${date}`;
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "project-key": VARIABLES.projectKey,
        "node-id": id,
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.data.length === 0) {
          setNoData(true);
          setMachineData([]);
        } else {
          setMachineData(response.data);
          setNoData(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, [currentDate]);
  return (
    <>
      <h6 className="ms-4">Production Count</h6>
      <div
        class="d-flex align-items-center justify-content-center ms-auto"
        style={{ width: "45%", height: "24px" }}
      >
        <label
          for="enddate"
          class="form-label me-2 pt-2 "
          style={{ height: "24px", fontSize: ".8em", textWrap: "nowrap" }}
        >
          Date
        </label>
        <input
          class="form-control"
          type="date"
          id="enddate"
          value={moment(currentDate).format("YYYY-MM-DD")}
          style={{ height: "24px", fontSize: ".8em", width: "60%" }}
          onChange={(e) => setCurrentDate(e.target.value)}
        />
      </div>
      {machineData.length !== 0 ? (
        <h1 className="text-center pt-2" style={{ fontSize: "2.5em" }}>
          {machineData.data[0]?.["Count 1"]}
        </h1>
      ) : noData ? (
        <h3 className="text-center pt-2" style={{ fontSize: "2em" }}>
          NO DATA
        </h3>
      ) : (
        <h3 className="text-center pt-2" style={{ fontSize: "2em" }}>
          Loading..
        </h3>
      )}
    </>
  );
}

export default ProductionCount;
