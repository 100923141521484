import React from "react";
import img from "../../res/Mattress gluing line-WF.png";

function Dashboard() {
  return (
    <div className="container mt-3">
      <img src={img} className="img-fluid" />
    </div>
  );
}

export default Dashboard;
