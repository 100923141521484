import React, { useState } from "react";
import { useForm } from "react-hook-form";

function Login() {
  const { register, handleSubmit } = useForm();
  const [seePassword, setSeePassword] = useState(false);
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        className="p-3 CardShadow"
        style={{
          minWidth: "350px",
          maxWidth: "400px",
          margin: "0px auto",
          borderRadius: "10px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-3">
          <h3>Login</h3>
        </div>
        <div class="mb-3">
          <label for="username" class="form-label">
            Username
          </label>
          <input
            type="text"
            class="form-control"
            id="username"
            {...register("username", { required: "true" })}
            placeholder="Enter username..."
          />
        </div>
        <div class="input-group mb-3">
          <input
            type={seePassword ? "text" : "password"}
            class="form-control"
            placeholder="Enter password..."
            {...register("password", { required: "true" })}
          />
          <span
            class="input-group-text"
            id="basic-addon2"
            style={{ cursor: "pointer" }}
            onClick={() => setSeePassword(!seePassword)}
          >
            <i class="bi bi-eye-fill"></i>
          </span>
        </div>
        <div class="col-12">
          <button
            type="submit"
            class="btn"
            style={{ backgroundColor: "#1c8adb", color: "white" }}
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
