import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts";
import DateDropdowns from "../dropdowns/DateDropdowns";
import axios from "axios";
import moment from "moment";
import { VARIABLES } from "../../Constants.js";
import { useParams } from "react-router-dom";

function ProductionLine() {
  const [avg, setAvg] = useState(0);
  const [year, setYear] = useState("24");
  const [month, setMonth] = useState(2);
  const [weekNo, setWeekNo] = useState(null);
  const [filter, setFilter] = useState("daily");
  const [machineData, setMachineData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [dailyDate, setDailyDate] = useState(new Date("2024-02-21"));
  const [label, setLabel] = useState("");
  const [noData, setNoData] = useState(false);
  const [tempData, setTempData] = useState(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }
  const getData = ({
    filter = "yearly",
    start_date = "",
    end_date = "",
    year = 2023,
    week = 42,
    month = 2,
    duration = "yearly",
    date = "2023-01-01",
  } = {}) => {
    let url = `${VARIABLES.url}count-api?duration=${duration}`;
    if (filter === "daily") {
      url += `&date=${date}`;
    }
    if (filter === "yearly") {
      url += `&year=${year}`;
    } else if (filter === "monthly") {
      url += `&year=${year}&month=${month}`;
    } else if (filter === "weekly") {
      url += `&year=${year}&month=${week}`;
    } else if (filter === "custom") {
      url += `&start_date=${start_date}&end_date=${end_date}`;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "project-key": VARIABLES.projectKey,
        "node-id": id,
      },
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (response.data.data.length === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        setIsLoading(false);
        setMachineData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setNoData(true);
        console.log(error);
      });
  };
  useEffect(() => {
    console.log(filter);
    if (filter === "daily") {
      getData({
        filter: filter,
        date: moment(new Date(dailyDate)).format("YYYY-MM-DD"),
        duration: filter,
      });
      setLabel("Time");
    }
    if (filter === "yearly") {
      if (year !== null) {
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: filter,
        });
        setLabel("Months");
      }
    }
    if (filter === "monthly") {
      if (year !== null && month !== null) {
        console.log(year);
        console.log(month);
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: filter,
          month: month,
        });
        setLabel("Days");
      }
    }
    if (filter === "weekly") {
      if (year !== null && weekNo !== null) {
        console.log(year);
        console.log(weekNo);
        getData({
          filter: filter,
          year: parseInt(`20${year}`),
          duration: filter,
          month: weekNo,
        });
      }
    }
    if (filter === "custom") {
      if (startDate !== null && endDate !== null) {
        getData({
          filter: filter,
          duration: filter,
          start_date: startDate,
          end_date: endDate,
        });
      }
    }
  }, [filter, year, month, weekNo, startDate, endDate, dailyDate]);
  console.log(VARIABLES[id]);
  useEffect(() => {
    if (tempData !== null && tempData) {
      let temp = 0;
      tempData.map((item) => {
        temp += item.production;
      });
      setAvg((temp / tempData.length).toFixed(2));
    }
  }, [tempData]);
  useEffect(() => {
    console.log(noData);
  }, [noData]);
  useEffect(() => {
    if (machineData !== null) {
      setTempData(
        machineData?.data[0]?.timestamp.map((time, i) => {
          return {
            name:
              filter !== "daily"
                ? filter === "yearly"
                  ? moment.utc(time).format("MMMM YY")
                  : moment.utc(time).format("MMM DD YY")
                : moment.utc(time).format("LT"),
            production: machineData?.data[0]?.["Count 1"][i],
          };
        })
      );
    }
  }, [machineData, filter]);
  console.log(tempData);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="GlassCard p-3">
          <p className="label">{`Production : ${payload[0].value}`}</p>
          <p className="intro">{label}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <h6 className="ms-4">
        Total Production {filter.charAt(0).toUpperCase() + filter.substring(1)}
      </h6>
      <DateDropdowns
        filter={filter}
        dailyDate={dailyDate}
        month={month}
        currentYearDefault={year}
        setFilter={setFilter}
        setWeekNo={setWeekNo}
        setMonth={setMonth}
        setYear={setYear}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setDailyDate={setDailyDate}
      />
      <ResponsiveContainer width="100%" height="80%">
        {!noData && machineData !== null ? (
          <LineChart
            data={tempData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" style={{ fontSize: ".8em" }}>
              <Label
                value={label}
                offset={-10}
                style={{
                  textAnchor: "middle",
                  fontSize: "80%",
                  fill: "rgba(0, 0, 0)",
                  fontWeight: "bolder",
                }}
                position="insideBottom"
              />
            </XAxis>
            <YAxis style={{ fontSize: ".8em" }}>
              <Label
                angle={270}
                position="insideLeft"
                offset={10}
                value="Count 1"
                style={{
                  textAnchor: "middle",
                  fontSize: "80%",
                  fill: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "bolder",
                }}
              />
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" />
            {/* <Line type="monotone" dataKey="IdleTime" stroke="#8884d8" /> */}
            <Line type="monotone" dataKey="production" stroke="#82ca9d" />
            <ReferenceLine
              y={avg}
              label={{
                value: `Average Production Rate (${avg})`,
                position: "bottom",
              }}
              stroke="red"
            />
          </LineChart>
        ) : !isLoading && tempData === null ? (
          <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
            NO DATA
          </h3>
        ) : (
          <h3 className="text-center pt-5" style={{ fontSize: "4em" }}>
            Loading..
          </h3>
        )}
      </ResponsiveContainer>
    </>
  );
}

export default ProductionLine;
